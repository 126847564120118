import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavbarBrand, Button } from "react-bootstrap";
import { BrightnessHigh, Moon } from "react-bootstrap-icons";

import "./Header.css";

const Header = () => {
  const [darkMode, setDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const body = document.body;

    // Si le dark mode est activé, ajout des classes de style dark
    // Sinon on supprime et on ajoute la classe light
    if (darkMode === true) {
      body.classList.remove("light-theme");
      body.classList.add("dark-theme");
    } else {
      body.classList.remove("dark-theme");
      body.classList.add("light-theme");
    }
  }, [darkMode]);

  return (
    <Navbar
      bg={darkMode ? "secondary" : "light"}
      variant={darkMode ? "dark" : "light"}
      expand="lg"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <NavbarBrand className="title ms-lg-5 me-sm-0 ms-sm-0 ">
        {" "}
        Pokemon Go Montpellier&nbsp;&nbsp;
      </NavbarBrand>
      <Navbar.Collapse id="basic-navbar-nav" className="ms-2">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/home">
            Accueil&nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link as={Link} to="/pokedex">
            Pokedex&nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link as={Link} to="/map">
            Map&nbsp;&nbsp;
          </Nav.Link>
        </Nav>
        <Button
          id="btnDark"
          className="color-theme"
          variant={darkMode ? "light" : "dark"}
          alt="Changer le thème"
          onClick={() => setDarkMode(!darkMode)}
        >
          {darkMode ? <BrightnessHigh /> : <Moon />}
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
