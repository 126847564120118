import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import Pokedex from "./view/Pokedex/Pokedex"
import Header from "./components/Header/Header";
import PokeMap from "./view/PokeMap/PokeMap";
import Home from "./components/Home.js/Home";
import Footer from "./components/Footer/Footer";

const App = ()=> {
  
  return (
    <BrowserRouter>
      <div>
        {<Header/>}
        <Routes>
          <Route path="/home" element={<Home/>} />
          <Route path="/pokedex" element={<Pokedex/>} />
          <Route path="/map" element={<PokeMap/>} />

          <Route path="*" element={<Home/>} />
        </Routes>
        {<Footer/>}
      </div>
    </BrowserRouter>
  );
}

export default App;
