import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Arrow90degRight,
  ArrowRight,
  ArrowRightCircleFill,
  ArrowRightSquareFill,
  Discord,
} from "react-bootstrap-icons";
import "./Home.css";
import logoPokedex from "../../assets/img/btn_action_menu.png";
import logoPokeballSombre from "../../assets/img/btn_pokeball_white_shadow.png";
import logoPokeballLight from "../../assets/img/pokeball_logo.png";
import { useEffect } from "react";

const Home = () => {
  return (
    <>
      <Container>
        <div className="d-grid justify-content-center">
          <div className="d-grid justify-content-center text-center title-home mt-5">
            <h1>Bienvenue sur le site POGO MONTPELLIER</h1>
            <Row>
              <Col>
                <a
                  href="https://discord.gg/b23VmMAczT"
                  className=""
                  target="_blank"
                >
                  <Button className="btn-discord">
                    <Discord
                      className="logo-discord"
                      alt="Lien vers le discord"
                      size={20}
                      title="Discord Icon"
                    />
                    <span> Rejoins-nous sur discord</span>
                  </Button>
                </a>
              </Col>

              <Col>
                <a href="/pokedex" className="">
                  <Button className="btn-pokedex">
                    <img
                      src={logoPokedex}
                      alt="Lien vers le pokedex"
                      className="logo-pokedex"
                      title="Pokedex link"
                    />
                    <span> Accède au pokedex</span>
                  </Button>
                </a>
              </Col>
            </Row>
          </div>

          <div className="home-text">
            <h5>
            <br/>
              <Col>
                Le pokedex liste les Pokémon présents sur Pokemon Go. Il est
                relié à un bot discord interne à la communauté, pour plus
                d'informations, n'hésite pas à nous rejoindre !
              </Col>
              <Col >
                La map référence les pokestops et les arènes de la ville. Si l'un d'entre eux est manquant n'hésite pas à nous le faire savoir ! 
              </Col>
            </h5>
          </div>

          {/* Image starters */}
          <div className="">
            <div className="d-grid justify-content-center">
            <div className="fire_box">
              <div className="fire_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/EfGMzaz.png"
                    alt="Charmander"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-6">
                  <div></div>
                  <div></div>
                  <div></div> {/*flames*/}
                </div>
              </div>

              <div className="fire_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/j5VFxjG.png"
                    alt="Cyndaquil"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-6">
                  <div></div>
                  <div></div>
                  <div></div> {/*flames*/}
                </div>
              </div>

              <div className="fire_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/eF0puNi.png"
                    alt="Torchic"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-6">
                  <div></div>
                  <div></div>
                  <div></div> {/*flames*/}
                </div>
              </div>

              <div className="fire_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/AK9YAff.png"
                    alt="Chimchar"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-6">
                  <div></div>
                  <div></div>
                  <div></div> {/*flames*/}
                </div>
              </div>

              <div className="fire_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/cUTYvSy.png"
                    alt="Tepig"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-6">
                  <div></div>
                  <div></div>
                  <div></div> {/*flames*/}
                </div>
              </div>

              <div className="fire_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/5nRyudS.png"
                    alt="Fennekin"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-6">
                  <div></div>
                  <div></div>
                  <div></div> {/*flames*/}
                </div>
              </div>
            </div>

            <div className="water_box">
              <div className="water_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/IucBolQ.png"
                    alt="Squirtle"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-8">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="water_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/sZK740C.png"
                    alt="Totodile"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-8">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="water_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/hwqk4UG.png"
                    alt="Mudkip"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-8">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="water_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/MMqavFA.png"
                    alt="Piplup"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-8">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="water_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/cVj9X76.png"
                    alt="Oshawott"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-8">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="water_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/xhctF2n.png"
                    alt="Froakie"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-8">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>

            <div className="grass_box">
              <div className="grass_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/IP0So6e.png"
                    alt="Bulbasaur"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-7">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="grass_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/hkFOoYn.png"
                    alt="Chikorita"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-7">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="grass_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/PdKkVpN.png"
                    alt="Treecko"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-7">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="grass_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/D1oYYuy.png"
                    alt="Turtwig"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-7">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="grass_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/4sKuE0u.png"
                    alt="Snivy"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-7">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="grass_avatar" style={{ padding: "5px" }}>
                <div id="avatar">
                  <img
                    src="https://i.imgur.com/0k4lRaW.png"
                    alt="Chespin"
                    width="167px"
                    height="200px"
                  />
                </div>
                <div className="inneravatar-7">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
        </div>
      </Container>
    </>
  );
};

export default Home;
