import axios from "axios";

const _getDataUtils = async (method, url) => {
  return await axios({
    method: method,
    url: url,
  })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return "Error catching data", error;
    });
};

export default _getDataUtils;
