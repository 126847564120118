import React, { useState, useEffect } from "react";
import { ArrowUp, ArrowUpSquare } from "react-bootstrap-icons";
import "./Pokedex.css";

const BtnScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset >= 500) {
      console.log("visibble");
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Gestion de la visibilité initiale lorsque la page est chargée
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className={`scroll-to-top-button ${isVisible ? "" : "d-none"}`}
        onClick={scrollToTop}
      >
        <ArrowUp />
      </div>
    </>
  );
};




export default BtnScrollUp;
